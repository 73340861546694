import * as React from 'react';
import { LoggerContext } from '@bojagi/react-event';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { Masthead } from '../sections/Masthead';
import { Introduction } from '../sections/Introduction';
import { KeyFeatures } from '../sections/KeyFeatures';
import { VisualInteractiveComponents } from '../sections/VisualInteractiveComponents';
import { InstantFeedback } from '../sections/InstantFeedback';
import { IntegrateWorkflow } from '../sections/IntegrationWorkflow';
import { ClosingBox } from '../sections/ClosingBox';

function LoggerWrapper({ section, children }) {
  return (
    <LoggerContext logImpression section={section}>
      <div>{children}</div>
    </LoggerContext>
  );
}

const IndexPage = () => (
  <Layout>
    <Seo title="Ship Flawless UIs in Shorter Cycle Times" />
    <Masthead />
    <Introduction />
    <div id="features" />
    <LoggerWrapper section="keyFeatures">
      <KeyFeatures />
    </LoggerWrapper>
    <LoggerWrapper section="visualInteractiveComponents">
      <VisualInteractiveComponents />
    </LoggerWrapper>
    <LoggerWrapper section="instantFeedback">
      <InstantFeedback />
    </LoggerWrapper>
    <LoggerWrapper section="integrateWorkflow">
      <IntegrateWorkflow />
    </LoggerWrapper>
    <LoggerWrapper section="closingBox">
      <ClosingBox title="Find &amp; resolve UI issues earlier than ever before.">
        By the time your product or feature is released to a test environment, it’s already too late
        to make meaningful changes. Bojagi lets you skip the queue, so you can test and give
        immediate feedback when it matters most.
      </ClosingBox>
    </LoggerWrapper>
  </Layout>
);

export default IndexPage;
