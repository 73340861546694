import React from 'react';
import { Box } from '@bojagi/pablo/Box';
import { Container } from '../components/Container';
import { Feature, FeatureList } from '../components/FeatureList';
import designerFriendlyImg from '../images/bulletpoint-designer-friendly.svg';
import ghIntegrationImg from '../images/bulletpoint-gh-integration.svg';
import storybookImg from '../images/bulletpoint-storybook.svg';

export function KeyFeatures() {
  return (
    <Container>
      <Box mb={[160, 270]}>
        <FeatureList>
          <Feature
            title="Designer Friendly"
            image={designerFriendlyImg}
            imageAltText="Wizard doing magic"
          >
            No background in development or coding is required. Bojagi is an intuitive, easy-to-use
            tool that designers can adopt in minutes.
          </Feature>
          <Feature
            title="GitHub Integration"
            image={ghIntegrationImg}
            imageAltText="Laptop with Bojagi and GitHub Stickers"
          >
            Bojagi has been built specifically for GitHub integration. Collaborating with developers
            has never been this easy.
          </Feature>
          <Feature title="Storybook Support" image={storybookImg} imageAltText="Storybook logo">
            Already using Storybook? Get started in minutes! Enjoy seamless Storybook Stories
            integration.
          </Feature>
        </FeatureList>
      </Box>
    </Container>
  );
}
