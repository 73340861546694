import React from 'react';
// import { interpolatePath } from "d3-interpolate-path";
import styled from 'styled-components';
import { levitate } from '../../keyframes/levitate';
import { shadowAnimation } from '../../keyframes/shadowAnimation';
import { WizardRobe } from './WizardRobe';
import { RobePath } from '../../components/RobePath';
import { SkinPath } from '../../components/SkinPath';
import { MagicGlow } from '../../components/MagicGlow';

const WizardGroup = styled.g`
  width: 101px;
  animation: ${levitate(10)} 4s ease-in-out infinite;
`;

const ShadowEllipse = styled.ellipse`
  fill: #e0dfe6;
  mix-blend-mode: multiply;
  animation: ${shadowAnimation} 4s ease-in-out infinite;
  transform-origin: ${props => `${props.cx}px ${props.cy}px`};
`;

export function WizardIllustration({ x, y }) {
  return (
    <g transform={`translate(${x} ${y})`}>
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="20.48"
          y1="136.48"
          x2="53.21"
          y2="94.7"
          gradientTransform="translate(45.37 -10.07) rotate(-0.78)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#483bdf" />
          <stop offset="1" stopColor="#6a50f2" />
        </linearGradient>
      </defs>
      <WizardGroup>
        <MagicGlow x={23.44} y={23.44} outerRadius={23.44} innerRadius={8.35} />
        <RobePath
          as="rect"
          x="13.54"
          y="23.86"
          width="3.85"
          height="64.53"
          rx="1.47"
          transform="translate(14.55 -2.09) rotate(14.5)"
        />
        <SkinPath
          as="circle"
          cx="46.64"
          cy="73.02"
          r="11.68"
          transform="translate(-17.66 14.95) rotate(-15.35)"
        />
        <SkinPath d="M76.81,71.38s1.53-5.8,3.15-4.86.69,3.52.69,3.52,4.11-4.55,5.42-2.32-2.51,3.89-2.51,3.89,4.81-.71,4.83.9-3.82,2.62-3.82,2.62,2.6,2.21,2,3.18-7.07-.54-7.07-.54" />
        <RobePath d="M63.08,76.7l13.7-7.57s7.76,3.43,4.49,23.38l-2.78,7.42-15.42-.11" />
        <WizardRobe />
        <RobePath d="M61.58,63.64c4.55,11.44,9.77,24.27,5.85,27.19-8.13,6-23.52-7.29-27.94-18.4s-2.38-31.38,7.66-32.56C52,39.29,57,52.2,61.58,63.64Z" />
        <RobePath d="M49.52,61.09c-.75,3.4-1.22,10,4.39,15a3.77,3.77,0,0,0,4.82.09C62.64,73,73.12,65.19,85.06,64.48c3.1-.18,2.3-1.56-.47-3.93-8.12-6.93-24-4.15-32.3-2.29A3.68,3.68,0,0,0,49.52,61.09Z" />
        <SkinPath d="M17,73.16,12,71.94S6.63,72,7.63,75.85s5.7,4.74,5.7,4.74l5.22,3.46Z" />
        <RobePath d="M44.51,80.81S27.35,70.13,14.85,71.9c-2.24,12.18,4.63,33.65,8,44.2,11.54-1.44,20.73-10.87,23.23-15.73" />
      </WizardGroup>
      <ShadowEllipse cx={52} cy={150} rx="24.68" ry="6.11" />
    </g>
  );
}
