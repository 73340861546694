import React from 'react';
import styled from 'styled-components';
import { Box } from '@bojagi/pablo/Box';
import bridgeImg from '../../images/integrationWorkflow/bridge.png';
import component1Img from '../../images/integrationWorkflow/component1.svg';
import component2Img from '../../images/integrationWorkflow/component2.svg';
import component3Img from '../../images/integrationWorkflow/component3.svg';
import component4Img from '../../images/integrationWorkflow/component4.svg';
import ghostImg from '../../images/integrationWorkflow/ghost.svg';
import { SmallBoxes } from './SmallBoxes';
import { WizardIllustration } from './Wizard';
import { levitate } from '../../keyframes/levitate';
import { shakeAnimation } from '../../keyframes/shakeAnimation';

interface NumberCoordinateProps {
  x: number;
  width: number;
  y: number;
  height: number;
}

interface HoveringImageProps extends NumberCoordinateProps {
  levitateOffset: number;
  levitateSpeed: number;
  levitateRotation: number;
}

const HoveringImage = styled.image<HoveringImageProps>`
  animation: ${props => levitate(props.levitateOffset, props.levitateRotation)}
    ${props => props.levitateSpeed}s ease-in infinite;
  transform-origin: ${props =>
    `${props.x + props.width * 0.5}px ${props.y + props.height * 0.5}px`};
`;

const Ghost = styled.image<NumberCoordinateProps>`
  animation: ${shakeAnimation} 4s ease-in-out infinite;
  transform-origin: ${props =>
    `${props.x + props.width * 0.5}px ${props.y + props.height * 0.5}px`};
`;

const GhostBoxImage = styled.image<NumberCoordinateProps>`
  animation: ${levitate(0, -15, 5)} 10s infinite;
  transform-origin: 77px 26px;
`;

const getRandomSign = () => (Math.round(Math.random()) ? 1 : -1);
const getLevitateSpeed = () => 5 + Math.random() * 8;
const getLevitateOffset = () => getRandomSign() * (7 + Math.random() * 6);
const getLevitateRotation = () => getRandomSign() * (3 + Math.random() * 5);

const createAnimationArray = (cb: () => number) => [cb(), cb(), cb(), cb()];

const boxLevitateSpeed = createAnimationArray(getLevitateSpeed);
const boxLevitateOffset = createAnimationArray(getLevitateOffset);
const boxLevitateRotation = createAnimationArray(getLevitateRotation);

const getAnimationProps = (index: number) => ({
  levitateSpeed: boxLevitateSpeed[index],
  levitateOffset: boxLevitateOffset[index],
  levitateRotation: boxLevitateRotation[index],
});

const StyledSvg = styled.svg`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export function IntegrateWorkflowIllustration() {
  return (
    <Box width={['100%', 520]} flexShrink={0} mr={[0, 0, 48]} mb={[8, 8, 0]}>
      <Box pb="91.1764706%" position="relative">
        <StyledSvg width={540} height={492} viewBox="0 0 580 529">
          <image href={bridgeImg} x={0} y={376} height={278} width={574} />
          <SmallBoxes />
          <HoveringImage
            href={component3Img}
            x={202}
            y={178}
            width={64}
            height={64}
            {...getAnimationProps(0)}
          />
          <HoveringImage
            href={component4Img}
            x={253}
            y={205}
            width={52}
            height={33}
            {...getAnimationProps(1)}
          />
          <HoveringImage
            href={component2Img}
            x={108}
            y={200}
            width={95}
            height={104}
            {...getAnimationProps(2)}
          />
          <g transform="translate(180, 286)">
            <HoveringImage as="g" x={0} y={0} width={114} height={101} {...getAnimationProps(3)}>
              <GhostBoxImage href={component1Img} x={0} y={0} width={114} height={80} />
              <Ghost href={ghostImg} width={52} height={46} x={52} y={-20} />
            </HoveringImage>
          </g>
          <WizardIllustration x={326} y={300} />
        </StyledSvg>
      </Box>
    </Box>
  );
}
