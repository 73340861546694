import React from 'react';
import { Title, Paragraph } from '@bojagi/pablo/Typography';
import { Box } from '@bojagi/pablo/Box';
import { TitleInfo } from '../../components/TitleInfo';
import { InstantFeedbackIllustration } from './Illustration';
import { Container } from '../../components/Container';

export function InstantFeedback() {
  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        maxWidth={[null, null, 980]}
        mx="auto"
        mb={[120, 270]}
      >
        <Box>
          <Box>
            <TitleInfo>Instant Feedback</TitleInfo>
            <Title>Resolve UI Issues In The Same Dev Cycle.</Title>
          </Box>
          <Box maxWidth={[null, null, 470]}>
            <Paragraph>
              Never again let design debt and a backlog of UI issues cripple your new feature
              release.
            </Paragraph>
            <Paragraph>
              With seamless GitHub integration, you can instantly relay feedback to developers, so
              UI issues get resolved immediately.
            </Paragraph>
            <Paragraph>
              Simply review developer changes, leave comments and approve the merge once resolved.
              You can even annotate feedback with drawings, so you literally point to the issue at
              hand!
            </Paragraph>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexDirection={['column', 'row']}
          justifyContent={['flex-start', 'flex-start', 'center']}
          mt={[8, 40]}
          flexShrink={0}
        >
          <Box mt={[0, 170, 0]} ml={[0, 0, -100]}>
            <InstantFeedbackIllustration />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
