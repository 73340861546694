import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import { mediaQueryBelow, useBreakpoint } from '@bojagi/pablo/breakpoints';
import { breakpoint } from '@bojagi/pablo/styleHelpers';
import { MagicGlow } from '../../components/MagicGlow';
import { useAnimationPlayback } from '../../context/animationPlaybackContext';
import hillImg from '../../images/masthead/hill.svg';
import { useFlawlessPosition } from './flawlessPositionContext';
import { Wizard } from './Wizard';
import { FLAWLESS_REPAIR_TIME, CAST_ANIMATION_TIME, WIZARD_FADE_IN_TIME } from '../../constants';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const magicAppearance = keyframes`
from, to {opacity: 0}
10%, 65% {opacity: 1}
`;

const magicSwirl = keyframes`
  from {
    transform: rotate( 0 ) translate3d( 0, 0, 0 )
  }
  
  50% {
    transform: translate3d( -2px, 1px, 0 ) rotate( 16deg ) 
  }

  to {
    transform: rotate( 360deg ) translate3d( 0, 0, 0 )
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  background-image: url(${hillImg});
  background-size: 200%;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  ${breakpoint(
    'sm',
    css`
      background-size: contain;
    `
  )}

  ${breakpoint(
    'lg',
    css`
      background-position: 100% 110%;
    `
  )}

${breakpoint(
  'xl',
  css`
    background-position: 100% 120%;
  `
)}
`;

const StyledSvg = styled.svg`
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: ${fadeIn} ${WIZARD_FADE_IN_TIME}s ease-in 1 forwards;
`;

interface MagicSwirlWrapperProps {
  centerX: number;
  centerY: number;
  play: boolean;
  delay: number;
}

const MagicSwirlWrapper = styled.g<MagicSwirlWrapperProps>`
  opacity: 0;
  ${props =>
    props.play &&
    css`
      animation: ${magicSwirl} 2.5s ease-in-out infinite ${props.delay}s,
        ${magicAppearance} ${FLAWLESS_REPAIR_TIME * 1.7}s ease-in-out 1
          ${CAST_ANIMATION_TIME * 0.25 + props.delay * 0.1}s;
    `}
  transform-origin: ${props => `${props.centerX}px ${props.centerY}px`};
`;

const MagicSwirlGroup = styled.g``;

const OFFSET = 76;

export function MastheadIllustration() {
  const isMobile = useBreakpoint('sm', mediaQueryBelow);
  const playAnimation = useAnimationPlayback();
  const [flawlessX, flawlessY, flawlessWidth, flawlessHeight] = useFlawlessPosition();
  const { height = 0, width = 0, ref } = useResizeObserver<HTMLDivElement>();
  const center = width * 0.5;
  const magicSwirlWrapperProps = {
    centerX: flawlessX + flawlessWidth * 0.5,
    centerY: flawlessY + flawlessHeight * 0.5,
  };
  return (
    <Wrapper ref={ref}>
      {!!height && !!width && (
        <StyledSvg>
          <Wizard
            x={center * (isMobile ? 0.75 : 1) + Math.min(400, width * (isMobile ? 0.2 : 0.3))}
            y={height - (isMobile ? 180 : 210)}
            play={playAnimation}
          />
          <MagicSwirlGroup>
            <MagicSwirlWrapper play={playAnimation} delay={0.5} {...magicSwirlWrapperProps}>
              <MagicGlow
                x={flawlessX + 30}
                y={flawlessY + flawlessHeight - OFFSET + 25}
                outerRadius={18.44}
                innerRadius={10.5}
              />
            </MagicSwirlWrapper>
            <MagicSwirlWrapper play={playAnimation} delay={0.1} {...magicSwirlWrapperProps}>
              <MagicGlow
                x={flawlessX + flawlessWidth * 0.55 - 15}
                y={flawlessY - OFFSET + 5}
                outerRadius={6.44}
                innerRadius={3.5}
              />
            </MagicSwirlWrapper>
            <MagicSwirlWrapper play={playAnimation} delay={0.3} {...magicSwirlWrapperProps}>
              <MagicGlow
                x={flawlessX + flawlessWidth * 0.85 - 10}
                y={flawlessY - OFFSET + 17}
                outerRadius={18.44}
                innerRadius={3.5}
              />
            </MagicSwirlWrapper>
            <MagicSwirlWrapper play={playAnimation} delay={0.7} {...magicSwirlWrapperProps}>
              <MagicGlow
                x={flawlessX - 15}
                y={flawlessY - OFFSET - 2}
                outerRadius={14.44}
                innerRadius={7.5}
              />
            </MagicSwirlWrapper>
          </MagicSwirlGroup>
        </StyledSvg>
      )}
    </Wrapper>
  );
}
