import React from 'react';
// import { interpolatePath } from "d3-interpolate-path";
import styled from 'styled-components';
import { glowAnimation } from '../keyframes/glowAnimation';

const WandGlow = styled.circle`
  fill: #d9d3ff;
  animation: ${glowAnimation(1.3)} 1s ease-in-out infinite;
  transform-origin: ${props => `${props.cx}px ${props.cy}px`};
`;

const OuterWandGlow = styled(WandGlow)`
  opacity: 0.4;
  animation: ${glowAnimation(1.1)} 0.8s ease-in infinite;
  transform-origin: ${props => `${props.cx}px ${props.cy}px`};
`;

export function MagicGlow({ x, y, innerRadius, outerRadius }) {
  return (
    <>
      <OuterWandGlow cx={x} cy={y} r={outerRadius} />
      <WandGlow cx={x} cy={y} r={innerRadius} />
    </>
  );
}
