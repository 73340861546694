import React from 'react';
import { Title, Paragraph } from '@bojagi/pablo/Typography';
import { LoggerContext } from '@bojagi/react-event';

import videoPlaceholderImg from '../images/video-placeholder.jpg';
import { IntroductionVideo } from '../components/IntroductionVideo';
import { IntroductionContainer } from '../components/IntroductionContainer';

export function Introduction() {
  return (
    <IntroductionContainer
      left={
        <>
          <Title>
            Streamline UI development,
            <br /> perfect product quality.
          </Title>
          <Paragraph>
            Bojagi empowers design and development teams with instant, close-knit collaboration on
            UI development.
          </Paragraph>
          <Paragraph>
            Our tool works by extracting <strong>React</strong> components from your web app, and
            then presenting them in their full visual &amp; interactive state.
          </Paragraph>
          <Paragraph>
            Designers can then test and leave feedback on new and updated components. All feedback
            is relayed to developers via GitHub, who can then make immediate changes to skyrocket
            product quality.
          </Paragraph>
        </>
      }
      right={
        <>
          <LoggerContext section="introduction">
            <IntroductionVideo
              title="Bojagi - Introduction"
              videoId="VY1pLuYimIE"
              placeholderImage={videoPlaceholderImg}
            />
          </LoggerContext>
        </>
      }
    />
  );
}
