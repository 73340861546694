import { Avatar } from '@bojagi/pablo/Avatar';
import { Box } from '@bojagi/pablo/Box';
import { breakpoint, getColor, getSpacing } from '@bojagi/pablo/styleHelpers';
import { InfoText, Paragraph } from '@bojagi/pablo/Typography';
import { Image } from '@bojagi/pablo/Image';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { mediaQueryBelow, useBreakpoint } from '@bojagi/pablo/breakpoints';
import { ParagraphLarge } from '../../components/ParagraphLarge';
import componentImg from './images/component.png';
import bubbleTriangleImg from './images/chatbubble_triangle.svg';
import avatar1 from './images/review_bubble_avatar_1.jpg';
import avatar2 from './images/review_bubble_avatar_2.jpg';
import approveIconImg from './images/approve_icon.svg';
import ghApproveIconImg from './images/gh_approve_icon.svg';
import bgImg from './images/bg.svg';

const Component = styled.div`
  width: 100vw;
  max-width: 460px;
  height: 153px;
  position: relative;
  background-image: url(${componentImg});
  background-repeat: no-repeat;
  background-size: contain;
`;

interface ChatBubbleBoxProps {
  showTriangle: boolean;
}

const ChatBubbleBox = styled.div<ChatBubbleBoxProps>`
  position: relative;
  background: #f3f3f3;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  padding: ${getSpacing(5)};
  margin-bottom: ${getSpacing(5)};
  box-sizing: border-box;
  max-width: 100vw;
  ${breakpoint(
    'sm',
    css`
      margin: ${getSpacing(3)};
      max-width: 380px;
    `
  )}
  display: flex;

  :after {
    display: ${props => (props.showTriangle ? 'block' : 'none')};
    content: '';
    width: 25px;
    height: 24px;
    position: absolute;
    left: auto;
    right: 40px;
    top: -22px;
    background-image: url(${bubbleTriangleImg});
    background-repeat: no-repeat;
    background-size: contain;
    transform: scaleX(-1) rotate(90deg);

    ${breakpoint(
      'sm',
      css`
        top: auto;
        right: auto;
        left: -22px;
        bottom: 12px;
        margin: ${getSpacing(3)};
        max-width: 380px;
        transform: rotate(0);
      `
    )}
  }
`;

interface ChatBubbleProps {
  children: ReactNode;
  avatarUrl: string;
  authorName: string;
  timestamp: string;
  showTriangle?: boolean;
}

function ChatBubble({
  children,
  avatarUrl,
  authorName,
  timestamp,
  showTriangle = false,
}: ChatBubbleProps) {
  return (
    <ChatBubbleBox showTriangle={showTriangle}>
      <Avatar variant="square" size="small" src={avatarUrl} mt={2} mr={5} />
      <Box>
        <Box display="flex">
          <InfoText mr={4}>{authorName}</InfoText>
          <InfoText textColor="gray.400">{timestamp}</InfoText>
        </Box>
        <ParagraphLarge inline>{children}</ParagraphLarge>
      </Box>
    </ChatBubbleBox>
  );
}

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${getSpacing(3)};
  box-sizing: border-box;
  ${breakpoint(
    'sm',
    css`
      margin: 0 -${getSpacing(3)};
    `
  )}
`;

const StatusBox = styled.div`
  border-radius: 8px;
  position: relative;
  background-color: ${getColor('common', 'white')};
  border: 1px solid #cdcdcd;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.1);
  padding: ${getSpacing(6)};
  width: 100%;

  box-sizing: border-box;
  ${breakpoint(
    'sm',
    css`
      margin: ${getSpacing(3)} 0;
      max-width: 220px;
      width: auto;
    `
  )}
  ${breakpoint(
    'md',
    css`
      flex-grow: 1;
    `
  )}
`;

const Background = styled.div`
  position: absolute;
  background-image: url(${bgImg});
  top: 100px;
  left: -160px;
  width: 645px;
  height: 492px;
  transform: rotate(-45deg);

  ${breakpoint(
    'sm',
    css`
      top: -240px;
      left: 50px;
      transform: rotate(0deg);
    `
  )}
`;

export function InstantFeedbackIllustration() {
  const isMobile = useBreakpoint('sm', mediaQueryBelow);
  return (
    <Box
      position="relative"
      css={css`
        user-select: none;
      `}
    >
      <Background />
      <Component />
      <Box
        position={['static', 'absolute']}
        left={[0, 310, 320]}
        bottom={-50}
        width={['100%', 400, 410]}
        px={[6, 0]}
      >
        <ChatContainer>
          <ChatBubble
            showTriangle={isMobile}
            authorName="Adela Milburn"
            timestamp="10 minutes ago"
            avatarUrl={avatar1}
          >
            Can you change the username text color?
          </ChatBubble>
          <ChatBubble authorName="Talan Curtis" timestamp="3 minutes ago" avatarUrl={avatar2}>
            Done!
          </ChatBubble>
          <ChatBubble
            showTriangle={!isMobile}
            authorName="Adela Milburn"
            timestamp="now"
            avatarUrl={avatar1}
          >
            Thanks, looks good now
          </ChatBubble>
        </ChatContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
          alignItems="center"
          width={['auto', 400, 460]}
        >
          <StatusBox>
            <Box display="flex" alignItems="center">
              <Image src={approveIconImg} mr={5} />
              <Paragraph inline>Adela approved the component</Paragraph>
            </Box>
          </StatusBox>
          <Box width={[2, 24]} height={[24, 2]}>
            <Box
              height="100%"
              borderBottom={[0, '2px dotted #B3B3B3']}
              borderRight={['2px dotted #B3B3B3', 0]}
            />
          </Box>
          <StatusBox>
            <Box display="flex" alignItems="center">
              <Image src={ghApproveIconImg} mr={5} />
              <Paragraph inline>Ready to Merge</Paragraph>
            </Box>
          </StatusBox>
        </Box>
      </Box>
    </Box>
  );
}
