import React from 'react';
import { Box } from '@bojagi/pablo/Box';
import { Title, Paragraph } from '@bojagi/pablo/Typography';
import { TitleInfo } from '../../components/TitleInfo';
import { VisualInteractiveComponentsIllustration } from './Illustration';
import { Container } from '../../components/Container';

export function VisualInteractiveComponents() {
  return (
    <Container>
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
        maxWidth={1100}
        mx="auto"
        mb={[180, 270]}
      >
        <VisualInteractiveComponentsIllustration />
        <Box>
          <Box>
            <TitleInfo>Visual &amp; Interactive Components</TitleInfo>
            <Title>Test Your Design Ideas Early.</Title>
          </Box>
          <Paragraph>
            Bojagi renders components in real HTML and CSS. Get an up-close, detailed look at how
            each component will appear in your final shipped product.
          </Paragraph>
          <Paragraph>
            Analyze each component carefully. Leave tailored, meaningful feedback. Keep a record of
            past component versions for future comparison.
          </Paragraph>
          <Paragraph>
            You can also play around with each component’s full interactive features. Take your time
            to thoroughly stress-test functionality, so you find issues before your users do.
          </Paragraph>
        </Box>
      </Box>
    </Container>
  );
}
