import React, { createContext, useContext } from 'react';

export type DimensionTuple = [number, number, number, number];

const flawlessContext = createContext<DimensionTuple>([0, 0, 0, 0]);

export const FlawlessPositionProvider = ({ x, y, width, height, children }) => (
  <flawlessContext.Provider value={[x, y, width, height]}>{children}</flawlessContext.Provider>
);

export const useFlawlessPosition = () => useContext(flawlessContext);
