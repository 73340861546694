import { keyframes } from 'styled-components';

export const glowAnimation = scale => keyframes`
  from, to {
    transform: scale(1);
  }

  50% {
    transform: scale(${scale});
  }
`;
