import { keyframes } from 'styled-components';

export const levitate = (
  offset: number,
  startRotation: number = 0,
  endRotation: number = 0
) => keyframes`
  from, to {
    transform: translateY(0px) rotate(${startRotation}deg);
  }

  50% {
    transform: translateY(${-offset}px) rotate(${endRotation}deg);
  }
`;
