import React, { useRef /* , useEffect, useCallback, useState */ } from 'react';
import { InnerRobePath, RobePath } from '../../components/RobePath';

const innerRobeKeyframes: string[] = [
  'M98.38,98.7C98.38,98.7 112.91,106.48 81.49,122.7C68.286,129.352 54.281,134.279 39.82,137.36C39.164,116.707 71.521,94.156 98.38,98.7Z',
  'M98.38,98.7S109.73,111.18,65.14,133c-16.29,8,-25.32,4.42,-25.32,4.42S34.19,126.72,46.06,118C59.76,108,91.62,99.75,98.38,98.7Z',
  'M98.38,98.7s13.94,5.83,-19.45,26a90.41,90.41,0,0,1,-39.11,12.72A34.94,34.94,0,0,1,53.48,116.1C67.18,106.09,91.62,99.75,98.38,98.7Z',
];

const robeKeyframes: string[] = [
  'M35.66,90.88 S32.38,133,39.82,137.39c0,0,25.68,-6,35.78,-19.16C92.37,96.35,98.38,98.7,98.38,98.7s-25.13,-6.08,-25.79,-7l-3.93,-5.73S52.75,72.67,39.38,81.52Z',
  'M35.66,90.88 S32.38,133,39.82,137.39c0,0,-.29,-10.59,11,-12.24C113.11,116.1,99.56,98.7,99.56,98.7s-26.31,-6.08,-27,-7l-3.93,-5.73S52.75,72.67,39.38,81.52Z',
  'M35.66,90.88 S32.38,133,39.82,137.39c0,0,3.23,-14.59,20.39,-18.11C115.47,107.93,99.56,98.7,99.56,98.7s-26.31,-6.08,-27,-7l-3.93,-5.73S52.75,72.67,39.38,81.52Z',
];

export function WizardRobe() {
  // const [startTime, setStartTime] = useState(0);
  const keyframeIndex = useRef(0);
  // const requestRef = useRef<number | null>(null);
  const currentRobePath = useRef(robeKeyframes[keyframeIndex.current]);
  const currentInnerRobePath = useRef(innerRobeKeyframes[keyframeIndex.current]);
  // const [tween, setTween] = useState(0);
  // useEffect(() => {
  //   setStartTime(performance.now());
  // }, []);
  // const animate = useCallback(
  //   time => {
  //     const newTween = ((time - startTime) % 400) / 400;
  //     setTween(newTween);
  //     keyframeIndex.current = Math.floor((time - startTime) / 400) % robeKeyframes.length;
  //     requestRef.current = requestAnimationFrame(animate);
  //   },
  //   [startTime]
  // );
  // useEffect(() => {
  //   if (startTime === 0) return () => {};
  //   requestRef.current = requestAnimationFrame(animate);
  //   return () => cancelAnimationFrame(requestRef.current as number);
  // }, [animate, startTime]);
  // useEffect(() => {
  //   const startPath = robeKeyframes[keyframeIndex.current];
  //   // const endPath = robeKeyframes[(keyframeIndex.current + 1) % robeKeyframes.length];
  //   // const pathInterpolate = interpolatePath(startPath, endPath);
  //   currentRobePath.current = startPath;
  //   // currentRobePath.current = pathInterpolate(tween);
  // }, [tween]);
  // useEffect(() => {
  //   const startPath = innerRobeKeyframes[keyframeIndex.current];
  //   // const endPath = innerRobeKeyframes[(keyframeIndex.current + 1) % robeKeyframes.length];
  //   // const pathInterpolate = interpolatePath(startPath, endPath);
  //   currentInnerRobePath.current = startPath;
  //   // currentInnerRobePath.current = pathInterpolate(tween);
  // }, [tween]);
  return (
    <>
      <InnerRobePath d={currentInnerRobePath.current} />
      <RobePath d={currentRobePath.current} />
    </>
  );
}
