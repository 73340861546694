import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { MagicGlow } from '../../components/MagicGlow';
import { InnerRobePath, RobePath } from '../../components/RobePath';
import { SkinPath } from '../../components/SkinPath';
import {
  BOLT_ANIMATION_TIME,
  CAST_ANIMATION_TIME,
  WIZARD_FADE_IN_TIME,
  WIZARD_HOVER_TIME,
} from '../../constants';
import { levitate } from '../../keyframes/levitate';
import { shadowAnimation } from '../../keyframes/shadowAnimation';

export interface Playable {
  play: boolean;
}

const glowCastAnimation = keyframes`
  from, 10% {
    opacity: 0;
  }

  25%, to {
    opacity: 1;
  }
`;

const boltAnimation = keyframes`
  from {
    transform: translate(0,0);
  }

  5% {
    opacity: 1;
  }

  30% {
    opacity: 1;
  }

  to {
    transform: translate(-150px,-110px);
    opacity: 0;
  }
`;

const createWandCastAnimation = (to: number, from: number = 0) => keyframes`
  from, 90%, to {
    transform: rotate(${from}deg);
  }

  20%, 85% {
    transform: rotate(${to}deg);
  }
`;

const createCastRotateAnimation = (to: number, from: number = 0) => keyframes`
  from, to {
    transform: rotate(${from}deg);
  }

  20% {
    transform: rotate(${to}deg);
  }

  50% {
    transform: rotate(${from + (to - from) * 0.75}deg);
  }

  85% {
    transform: rotate(${to}deg);
  }

  90% {
    transform: rotate(${from - (to - from) * 0.25}deg);
  }
`;

interface BoltWrapperProps extends Playable {
  delay?: number;
}

const BoltWrapper = styled.g<BoltWrapperProps>`
  opacity: ${props => (props.delay ? 0 : 1)};
  ${props =>
    props.play &&
    css`
      animation: ${boltAnimation} ${BOLT_ANIMATION_TIME - (props.delay || 0) * 1.2}s ease-out
        ${WIZARD_FADE_IN_TIME + CAST_ANIMATION_TIME * 0.875 + (props.delay || 0)}s forwards;
    `}
`;

const GlowWrapper = styled.g<Playable>`
  opacity: 0;
  ${props =>
    props.play &&
    css`
      animation: ${glowCastAnimation} ${CAST_ANIMATION_TIME}s ease-in-out 1 ${WIZARD_FADE_IN_TIME}s
        forwards;
    `}
`;

const Head = styled.g<Playable>`
  ${props =>
    props.play &&
    css`
      animation: ${createCastRotateAnimation(4)} ${CAST_ANIMATION_TIME}s ease-in-out 1
        ${WIZARD_FADE_IN_TIME}s;
    `}
  transform: rotate(0deg);
  transform-origin: 50px 42px;
`;

const RightArm = styled.g<Playable>`
  ${props =>
    props.play &&
    css`
      animation: ${createCastRotateAnimation(6)} ${CAST_ANIMATION_TIME}s ease-in-out 1
        ${WIZARD_FADE_IN_TIME}s;
    `}
  transform: rotate(0deg);
  transform-origin: 60px 52px;
`;

const LeftArm = styled.g<Playable>`
  ${props =>
    props.play &&
    css`
      animation: ${createCastRotateAnimation(25)} ${CAST_ANIMATION_TIME}s ease-in-out 1
        ${WIZARD_FADE_IN_TIME}s;
    `}
  transform-origin: 70px 65px;
`;

const WizardGroup = styled.g`
  width: 137px;
  animation: ${levitate(16)} ${WIZARD_HOVER_TIME}s ease-in-out infinite;
`;

const ShadowEllipse = styled.ellipse`
  fill: #e0dfe6;
  mix-blend-mode: multiply;
  animation: ${shadowAnimation} ${WIZARD_HOVER_TIME}s ease-in-out infinite;
  transform-origin: ${props => `${props.cx}px ${props.cy}px`};
`;

const Wand = styled.rect<Playable>`
  fill: #050505;
  transform: rotate(-42.13deg);
  ${props =>
    props.play &&
    css`
      animation: ${createWandCastAnimation(-20.13, -42.13)} ${CAST_ANIMATION_TIME}s ease-in-out 1
        ${WIZARD_FADE_IN_TIME}s;
    `}
  transform-origin: 20px 60px;
`;

export interface WizardProps {
  x: number;
  y: number;
  play: boolean;
}

export function Wizard({ x, y, play }: WizardProps) {
  return (
    <g transform={`translate(${x} ${y}) scale(1.1)`}>
      <defs>
        <linearGradient
          id="a"
          x1="-36.77"
          y1="238.17"
          x2="-3.67"
          y2="195.91"
          gradientTransform="matrix(0.89, -0.36, 0.37, 0.91, 51.59, -148.02)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#483bdf" />
          <stop offset="1" stopColor="#6a50f2" />
        </linearGradient>
      </defs>
      <WizardGroup>
        <RightArm play={play}>
          <SkinPath d="M102.16,18.32s-1.13-6.48.92-6.28,2.29,3.2,2.29,3.2,2-6.42,4.35-4.8-.73,5-.73,5,4.47-2.9,5.22-1.31-2.6,4.35-2.6,4.35,3.6,1,3.41,2.28-7.29,2.68-7.29,2.68" />
          <RobePath d="M90.9,29.9l10.21-13.81s9.29-.12,15.15,21.25l.61,8.68L101.46,53" />
        </RightArm>
        <Head play={play}>
          <SkinPath
            as="circle"
            cx="72.83"
            cy="33.74"
            r="12.8"
            transform="translate(-7.43 34.5) rotate(-25.4)"
          />
          <RobePath d="M83.44,17.56C93.2,26.89,104.26,37.29,101.69,42c-5.34,9.75-26.77,3.47-36.25-5.58S48.74,6.22,58.21.46C62.8-2.34,73.68,8.24,83.44,17.56Z" />
          <RobePath d="M70.26,20.53c.81,3.73,3.34,10.51,11.2,12.9a4.12,4.12,0,0,0,4.84-2.12c2.49-4.88,9.35-17.47,20.93-23.63,3-1.6,1.58-2.61-2.27-3.7C93.71.78,79.19,10.78,71.73,16.44A4,4,0,0,0,70.26,20.53Z" />
        </Head>
        <LeftArm play={play}>
          <g transform="translate(10 -10)">
            <BoltWrapper play={play}>
              <GlowWrapper play={play}>
                <MagicGlow x={5} y={8.44} outerRadius={23.44} innerRadius={8.35} />
              </GlowWrapper>
            </BoltWrapper>
            <BoltWrapper play={play} delay={0.125}>
              <MagicGlow x={50} y={40.44} outerRadius={14.44} innerRadius={6.5} />
            </BoltWrapper>
            <Wand play={play} x="22.58" y="6.48" width="4.22" height="70.75" rx="1.47" />
          </g>
          <SkinPath d="M44.36,51.76l-5.28,1.81s-4.94,3.28-1.75,6.23,8.07,1,8.07,1l6.87.1Z" />
          <RobePath d="M74.27,42.47s-22.16.34-32.63,9.4C46.81,64.42,55.75,88.7,65.1,96.43,82.64,85.34,93,64.36,92.45,58.4" />
        </LeftArm>
        <InnerRobePath d="M103,70.83s27.55-24.12,33.09-22.48c2.59.77-7.27,22.28-15.14,31.25C93,111.41,85.82,100.75,85.82,100.75S79.19,84.92,103,70.83Z" />
        <RobePath d="M69.22,45.08c10-14.42,31.63-7.32,31.63-7.32L107.16,42c1.06.71,29,6.16,29,6.16S128.35,76.72,102.26,87c-19.51,7.68-16,14.38-16,14.38-9.37-1.47-17-45.24-17-45.24Z" />
      </WizardGroup>
      <ShadowEllipse cx={92} cy={140} rx="24.68" ry="6.11" />
    </g>
  );
}
