import { keyframes } from 'styled-components';

export const twinkleAnimation = keyframes`
  from, to {
    transform: scale(0.85);
  }
  50% {
    transform: scale(1.15);
  }  
`;
