import React, { useState, useCallback } from 'react';
import { Box } from '@bojagi/pablo/Box';
import { Headline, Paragraph } from '@bojagi/pablo/Typography';
import { breakpoint } from '@bojagi/pablo/styleHelpers';
import styled, { css, keyframes } from 'styled-components';
import { useLogger } from '@bojagi/react-event';
import useResizeObserver from 'use-resize-observer';
import { ArrowButton } from '../../components/ArrowButton';
import bojagiLogo from '../../images/bojagi-logo.svg';
import { MastheadIllustration } from './Illustration';
import { useAnimationPlayback } from '../../context/animationPlaybackContext';
import { FlawlessPositionProvider } from './flawlessPositionContext';
import { FLAWLESS_REPAIR_DELAY, FLAWLESS_REPAIR_TIME } from '../../constants';

const flawlessRepair = keyframes`
from {
  transform: rotate(15deg);
}

65%, 85% {
  transform: rotate(18deg);
}

75%, 95% {
  transform: rotate(12deg);
}
to {
  transform: rotate(0deg);
}
`;

interface FlawlessWrapperProps {
  play: boolean;
}

const FlawlessWrapper = styled.span<FlawlessWrapperProps>`
  display: inline-block;
  transform: rotate(15deg);
  ${props =>
    props.play &&
    css`
      animation: ${flawlessRepair} ${FLAWLESS_REPAIR_TIME * 0.8}s ease-in-out
        ${FLAWLESS_REPAIR_DELAY * 1.3}s forwards;
    `}
  transform-origin: center center;
`;

const LogoImg = styled.img`
  height: 48px;
  ${breakpoint(
    'sm',
    css`
      height: 72px;
    `
  )}
`;

export function Masthead() {
  const { height = 0, width = 0, ref } = useResizeObserver<HTMLSpanElement>();
  const playAnimation = useAnimationPlayback();

  const logger = useLogger({
    section: 'masthead',
  });

  const [flawlessX, setFlawlessX] = useState(0);
  const [flawlessY, setFlawlessY] = useState(0);

  const setFlawlessRef = useCallback(
    newEl => {
      ref(newEl);
      setFlawlessX(newEl?.getBoundingClientRect().left);
      setFlawlessY(window.scrollY + newEl?.getBoundingClientRect().top);
    },
    [ref]
  );

  return (
    <FlawlessPositionProvider x={flawlessX} y={flawlessY} width={width} height={height}>
      <Box width="100vw" position="relative">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt={[0, 72]}
          mt={[-12, 0]}
          pb={220}
          maxWidth={800}
          px={6}
          mx="auto"
        >
          <Box mb={[24, 62]}>
            <LogoImg id="bjg-intro-logo" alt="Bojagi Logo" height={72} src={bojagiLogo} />
          </Box>
          <Headline fontSize={['1.6em', '2.25rem']} textAlign={['center', 'left']}>
            Ship{' '}
            <FlawlessWrapper play={playAnimation} ref={setFlawlessRef}>
              Flawless
            </FlawlessWrapper>{' '}
            UIs in Shorter Cycle Times
          </Headline>
          <Paragraph
            fontSize={['1.2rem', '1.5rem']}
            lineHeight={1.6}
            textAlign="center"
            mb={[32, 50]}
          >
            Bojagi enables instant feedback on visual & interactive web components so UI issues get
            resolved immediately.
          </Paragraph>
          <Box display="flex" flexDirection={['column', 'row']} mx={[0, -6]}>
            <ArrowButton
              to="/register"
              onClick={logger.createHandler({
                component: 'registerBtn',
                action: 'click',
              })}
              mx={[0, 6]}
              mb={[4, 0]}
            >
              Register for Free
            </ArrowButton>
            <ArrowButton
              to="/demo"
              onClick={logger.createHandler({
                component: 'interactiveDemoBtn',
                action: 'click',
              })}
              variant="secondary"
              mx={[0, 6]}
              mb={[4, 0]}
            >
              Interactive Demo
            </ArrowButton>
          </Box>
        </Box>
        <MastheadIllustration />
      </Box>
    </FlawlessPositionProvider>
  );
}
