import React from 'react';
import styled from 'styled-components';
import { twinkleAnimation } from '../../keyframes/twinkleAnimation';

interface TwinklingShapeProps {
  width?: number;
  height?: number;
  r?: number;
  delay: number;
}

const TwinklingShape = styled.rect<TwinklingShapeProps>`
  transform: scale(0.85);
  animation: ${twinkleAnimation} 1.25s ease-in-out infinite;
  animation-delay: ${props => props.delay}s;
  transform-origin: ${props =>
    `${props.r ? 0 : props.width! * 0.5}px ${props.r ? 0 : props.height! * 0.5}px`};
`;

const getDelay = () => 3 * Math.random();

const delays = [
  getDelay(),
  getDelay(),
  getDelay(),
  getDelay(),
  getDelay(),
  getDelay(),
  getDelay(),
  getDelay(),
  getDelay(),
  getDelay(),
];

export function SmallBoxes() {
  return (
    <g transform="translate(80, 132.87)">
      <g transform="translate(34.5, 151.96)">
        <TwinklingShape delay={delays[0]} as="circle" fill="#b1a5f2" r={1.89} />
      </g>
      <g transform="translate(213.5, 119.19)">
        <TwinklingShape delay={delays[1]} fill="#b1a5f2" width={9.47} height={9.47} />
      </g>
      <g transform="translate(245.69, 68.13)">
        <TwinklingShape delay={delays[2]} as="circle" fill="#91d4fa" r={1.89} />
      </g>
      <g transform="translate(69.61, 50.47)">
        <TwinklingShape delay={delays[3]} as="circle" fill="#91d4fa" r={3.16} />
      </g>
      <g transform="translate(52.91, 20.9)">
        <TwinklingShape delay={delays[4]} as="circle" fill="#fceb90" r={4.42} />
      </g>
      <g transform="translate(53.97, 169.09)">
        <TwinklingShape delay={delays[5]} as="circle" fill="#91d4fa" r={3.79} />
      </g>
      <g transform="translate(126.48, 113.92)">
        <TwinklingShape delay={delays[6]} fill="#ffc4d5" width={10.1} height={10.1} />
      </g>
      <g transform="translate(0, 71.33)">
        <TwinklingShape delay={delays[7]} fill="#ffc4d5" width={10.1} height={10.1} />
      </g>
      <g transform="translate(198.06, 32.83)">
        <TwinklingShape delay={delays[8]} fill="#fceb90" width={6.32} height={6.32} />
      </g>
      <g transform="translate(133.01, 12.63)">
        <TwinklingShape delay={delays[9]} fill="#ffc4d5" width={12.63} height={12.63} />
      </g>
    </g>
  );
}
