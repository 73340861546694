import { keyframes } from 'styled-components';

export const shakeAnimation = keyframes`
  0% { transform: rotate(2deg); }
  10% { transform: rotate(-1deg); }
  20% { transform: rotate(3deg); }
  30% { transform: rotate(-2deg); }
  40% { transform: rotate(1deg); }
  50% { transform: rotate(-2deg); }
  60% { transform: rotate(2deg); }
  70% { transform: rotate(-1deg); }
  80% { transform: rotate(3deg); }
  90% { transform: rotate(-3deg); }
`;
