import { keyframes } from 'styled-components';

export const shadowAnimation = keyframes`
  from, to {
    transform: scale(1);
  }

  50% {
    transform: scale(0.85);
  }
`;
