import React from 'react';
import { Title, Paragraph } from '@bojagi/pablo/Typography';
import { Box } from '@bojagi/pablo/Box';
import { TitleInfo } from '../../components/TitleInfo';
import { Container } from '../../components/Container';
import { IntegrateWorkflowIllustration } from './Illustration';

export function IntegrateWorkflow() {
  return (
    <Container wide>
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent="space-between"
        alignItems="center"
        mb={[160, 270]}
      >
        <IntegrateWorkflowIllustration />
        <Box>
          <Box>
            <TitleInfo>Integrate with your workflows</TitleInfo>
            <Title>
              Bring your design to life, <br /> exactly the way you planned it.
            </Title>
          </Box>
          <Paragraph>
            Using Bojagi, designers can be set as the final gatekeepers of UI and product release
            quality.
          </Paragraph>
          <Paragraph>
            As developers build or update components, you can approve, comment on or reject designs.
            If issues are not resolved, the task is not signed off by the designer.
          </Paragraph>
          <Paragraph>
            Bojagi also works in any continuous-integration environment, and can be tailored to your
            workflows.
          </Paragraph>
          <Paragraph>
            Wanna be strict? Set designers as required approvers to merge Pull Requests. Or, be
            casual and ping them in Slack, so they can see their designs in action.
          </Paragraph>
        </Box>
      </Box>
    </Container>
  );
}
